<template>
  <b-card class="EmptyState text-center" bg-variant="light">
    <i class="text-secondary fas fa-file-alt fa-6x mb-3"></i>
    <h3 class="text-secondary font-weight-bold">
      This collection does not contain geo_point field
    </h3>
  </b-card>
</template>

<script>
export default {}
</script>
